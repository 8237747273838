<template>
	<div class="container">
		<LoginWrap v-if="showLogin"></LoginWrap>
		<div class="zhanwei20"></div>
		<div class="flex-row align-center space-between creator-sec">
			<div class="flex-row align-center">
				<img v-if="info.userPic" src="../assets/mine/head_icon.png" class="creator-head" />
				<img v-else src="../assets/mine/head_icon.png" class="creator-head" />
				<div class="flex-column justify-center creator-name-sec">
					<div class="flex-row align-center">
						<div class="creator-user-name">{{info.userNickName}}</div>
						<div v-if="info.userId== 163598" class="gf">官方</div>
						<div v-if="info.userId== 163666 || info.userId==163690" class="sgf">项目管理员</div>
					</div>
					<div class="creator-time">{{info.createTime}}</div>
				</div>
			</div>
			<template v-if="islogin">
				<div class="attention-btn" v-if="isFollow == 0" @click.stop="addFollow">关注</div>
				<div class="attention-btn-false" v-else @click.stop="delFollow">已关注</div>
			</template>
		</div>
		<div class="detail-sec relative">
			<img src="../assets/finishi_icon.png" class="status-icon" v-if="info.status == 7" />
			<img src="../assets/abandon_icon.png" class="status-icon" v-if="info.status == 8" />
			<img src="../assets/gongshi_icon.png" class="status-icon" v-if="info.status == 9" />
			<div class="title">{{info.title}}</div>
			<div class="desc">{{info.content}}</div>
			<div class="flex-column align-center detail-items-sec">
				<img src="../assets/initiate/please_icon.png" class="please-icon" />
				<!-- <div class="end_days" v-if="true">距投票结束还有：{{info.formatDate}}天</div> -->
				<div class="end_days" v-if="true">投票截止时间：{{info.formatDate2}}</div>
				<div class="end_days" v-else>已结束</div>

				<div class="flex-row align-center space-between item-item relative"
					v-for="(item,index) in info.voteOptionList" :key="index">
					<div class="flex-row align-center justify-end"
						:class="item.currentSupportRate==100?'item-persent-sec':'item-persent-sec-small'"
						:style="'width:'+item.currentSupportRate+'%'" v-if='item.currentSupportRate>0'>
						<div class="persent-bg" v-if="item.currentSupportRate<10"></div>
						<div class="persent-bg2" v-else></div>
					</div>
					<div class="flex-row align-center">
						<div class="item-icon">{{words[index]}}</div>
						<div :class="item.select == 0?'item-text':'item-text-active'">{{item.voteOption}}</div>
						<div class="item-persent-small-num">
							{{item.currentSupportRate}}%
						</div>
					</div>
					<div class="item-select" v-if="item.select"></div>
				</div>
				<div class="cureent-nft">
					参与nft数量：{{info.currentNft}}
				</div>

				<div class="rule-sec relative">
					<div class="flex-row align-center justify-center rule-img-sec">
						<div class="rule-left"></div>
						<img src="../assets/initiate/rule_icon.png" class="rule-icon" />
						<div class="rule-right"></div>
					</div>
					<div class="rule-con">
						<div>投票规则：</div>
						<div>
							<!-- 1.参与投票的份数，必须大于等于总发行量的{{info.minSupportRatio}}%，否则无效。例如，总发行量10000份，参与投票必须大于等于{{info.minSupportRatio*100}}份。 -->
							1.参与投票的份数，必须大于等于{{info.minSupportRatio/10}}份，否则无效。
						</div>
						<div>
							2.得票最高的选项，必须大于等于{{info.targetSupportRate}}%，否则无效。例如10000份参与，得票最高的选项，必须大于等于{{info.targetSupportRate}}%，即{{info.targetSupportRate*100}}份。
						</div>
					</div>
					<div class="flex-row align-center space-between ipfs-sec">
						<div class="ipfs">IPFS</div>
						<div class="ipfs-con" @click.stop="navVoteDetail()" v-if="info.formatVoteRecordUrl !=''">
							{{info.formatVoteRecordUrl}}
						</div>
					</div>
					<div class="flex-row align-center space-between zhifu-sec">
						<div class="zhifu">投票制度</div>
						<div class="zhifu-con">单选投票</div>
					</div>
				</div>
				<div class="toupiao-btn" @click="toupiao()" v-if="info.status==1||info.status==2">点击投票</div>
				<div class="toupiao-btn-grey" v-else>投票已结束</div>
			</div>
			<div class="gongshi-sec flex-column align-center" v-if="info.status == 7">
				<div class="gongshi-title">等待公示执行文件</div>
				<van-uploader :after-read="afterRead" :max-count="1" accept="image/*" v-if="isAdmin">
					<div class="gongshi-upload-sec flex-row align-center justify-center">
						<img src="../assets/initiate/rule_icon.png" class="gongshi-upload-icon" />
						<div class="upload-text">上传文件</div>
					</div>
				</van-uploader>
			</div>
		</div>
		<!-- 公示文件部分 -->
		<div class="gongshi-file-sec" v-if="info.status == 9">
			<img :src="info.publicizeFile" class="gongshi-file-img" title="公示文件" />
		</div>
		<!-- 投票明细列表 -->
		<div class="vote-list">
			<div class="flex-row align-center justify-center vote-title">
				<img src="../assets/vote_icon.png" class="vote-icon" />
				<div>投票明细</div>
			</div>
			<div class="flex-row align-center space-between vote-list-items">
				<div class="flex-row align-center">
					<div class="vote-list-user">用户</div>
					<div class="vote-list-choice">投票项</div>
				</div>
				<div class="vote-list-num">票数</div>
			</div>
			<van-list v-model="recordData.loading" :immediate-check="recordData.immediateCheck"
				@load="getVoteRecordList" :finished="recordData.finished" finished-text="没有更多了"
				:error.sync="recordData.error" error-text="请求失败，点击重新加载">
				<div class="flex-row align-center space-between vote-item" v-for="(item,index) in recordData.list"
					:key="index">
					<div class="flex-row align-center">
						<div class="flex-row align-center vote-item-user">
							<img v-if="item.userPic" :src="item.userPic" class="vote-item-head" />
							<img v-else src="../assets/mine/head_icon.png" class="vote-item-head" />
							<div class=" vote-item-name">{{item.userAddress}}
							</div>
						</div>
						<div class="vote-item-choice">{{item.seq}}</div>
					</div>
					<div class="vote-item-num">{{item.productQuantity}} NFT</div>
				</div>
			</van-list>
		</div>
		<!-- 占位 -->
		<div class="height150"></div>
		<!-- 底部评论区域 -->
		<!-- <div class="review-input-sec">
			<div class="review-input-con-sec flex-row align-center">
				<div class="review-input">文明发言</div>
				<div class="review-sec flex-row align-center" @click="showReviewList()">
					<img src="../assets/initiate/review_icon.png" class="review-icon" />
					<div class="review-num">12343</div>
				</div>
				<div class="review-sec flex-row align-center" @click="collect()">
					<img src="../assets/initiate/collect_icon.png" class="review-icon" />
					<div class="review-num">12343</div>
				</div>
			</div>
		</div> -->
		<!-- 点击投票弹窗 -->
		<div class="toupiao-modal-mask" v-if='showToupiaoModal' @click.stop="closeToupiaoModal()">
			<div class="relative toupiao-modal" @click="stop()">
				<img src="../assets/initiate/toupiao_bg.png" class="toupiao-bg" />
				<div class="toupiao-modal-con flex-column align-center">
					<div class="flex-column align-center toupiao-can-sec">
						<!-- 系统检测到您最多可以投{{userVoteNum}}票，请提交您的投票份数。 -->
						<div class="toupiao-can-text">系统检测到您最多可以投</div>
						<div class="toupiao-can-num">{{userVoteNum}}票</div>
					</div>
					<div class="choice-title-sec flex-row align-center">
						<img src="../assets/triangle_icon.png" class="choice-left-icon" />
						<div class="choice-text">请选择投票的选项</div>
						<img src="../assets/triangle_icon.png" class="choice-right-icon" />
					</div>
					<div class="choice-items">
						<div class="flex-row align-center space-between choice-item"
							v-for="(item,index) in info.voteOptionList" :key="index" @click.stop='selectOption(index)'>
							<div class="flex-row align-center">
								<div class="choice-item-icon">{{words[index]}}</div>
								<div class="choice-item-text">{{item.voteOption}}</div>
							</div>
							<div class="choice-select" v-if="userChoiceItemIndex==index" @click="selectChoice(index)">
							</div>
							<div class="choice-no-select" v-else></div>
						</div>
					</div>
					<div class="fenshu-title-sec flex-row align-center">
						<img src="../assets/triangle_icon.png" class="choice-left-icon" />
						<div class="choice-text">请提交投票份数</div>
						<img src="../assets/triangle_icon.png" class="choice-right-icon" />
					</div>
					<div class="fill-toupiao-input">
						<input placeholder="请填写票数" @input="voteNumChange" v-model="voteNum" type="number"
							class="fill-toupiao-num" />
					</div>
					<div class="submit-btn" @click="submit()">提交</div>
				</div>
			</div>
			<div class="blockchain-sign-sec flex-column align-center" v-if="showSign">
				<div class="blockchain-sign-title">区块链签名页</div>
				<div class="blockchain-sign-top flex-column">
					<div>{</div>
					<div>"proposal":"{{signData.other.proposal}}"</div>
					<div>"choice":"{{signData.other.choice}}"</div>
					<div>"app":"{{signData.other.app}}"</div>
					<div>"from":"{{signData.other.from}}"</div>
					<div>"timestamp":"{{signData.other.timestamp}}"</div>
					<div>}</div>
				</div>
				<div class="blockchain-sign-bottom">{{signData.sign}}</div>
				<div class="blockchain-sign-btn" @click.stop="next()">区块链签名</div>
			</div>
		</div>
		<!-- 投票失败提示弹窗 -->
		<div class="fail-modal-mask" v-if='showFailModal'>
			<div class="fail-modal flex-column align-center">
				<img src="../assets/fail_tishi_icon.png" class="fail-tishi-icon" />
				<div class="toupiao-tishi-title">投票提示</div>
				<div class="toupiao-tishi-con">验证未通过，没有相关NFT，无法投票。</div>
				<div class="flex-row align-center toupiao-fail-btn-sec">
					<div class="toupiao-fail-btn" @click="abandon()">放弃投票</div>
					<div class="toupiao-fail-btn2" @click="goDeal()">去交易市场</div>
				</div>
			</div>
		</div>

		<!-- 评论列表开始 -->
		<div class="review-sec">
			<div class="review-all-num">{{total}}条网友热评</div>
			<van-list v-model="loading" :immediate-check="immediateCheck" @load="getCommentListByTargetId"
				:finished="finished" finished-text="没有更多了" :error.sync="error" error-text="请求失败，点击重新加载">
				<div class="review-item-sec" v-for="(item,index) in list" :key="index">
					<div class="flex-row align-top">
						<img v-if="item.userPic" :src="item.userPic" class="review-item-head" />
						<img v-else src="../assets/mine/head_icon.png" class="review-item-head" alt="">
						<div class="flex-column">
							<div class="flex-row align-center">
								<div class="review-item-user">{{item.userName}}</div>
								<div class="author-icon" v-if="item.userId == info.userId">作者</div>
								<div v-if="item.userId== 163666 || item.userId==163690" class="sgf2">项目管理员</div>
							</div>
							<div class="review-item-con">{{item.content}}</div>
							<div class="flex-row align-center space-between">
								<div class="flex-row align-center">
									<div class="review-item-date">{{item.createTime}}</div>
									<div class="response-btn" @click="responseReview(index,2)">回复</div>
									<div class="author-praise-status" v-if="item.authorLike == 1">作者赞过</div>
								</div>
								<div class="flex-row align-center" @click="praiseReview(index,-1,1)">
									<img src="../assets/praise_icon_select.png" class="review-item-praise-icon"
										v-if="item.isLike==1" />
									<img src="../assets/praise_icon.png" class="review-item-praise-icon" v-else />
									<div class="review-item-praise-num" v-if="item.likeTotal >0">
										{{item.formatLikeTotal}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="response-list">
						<template v-for="(itm,idx) in item.replyPageInfo.list">
							<div class="flex-row align-top response-item" :key="idx">
								<img v-if="itm.userPic" :src="itm.userPic" class="review-item-head2" />
								<img v-else src="../assets/mine/head_icon.png" class="review-item-head2" />
								<div class="flex-column">
									<div class="flex-row align-center">
										<div class="review-item-user">{{itm.userName}}</div>
										<div class="author-icon" v-if="itm.userId == info.userId">作者</div>
										<div v-if="itm.userId== 163666 || itm.userId==163690" class="sgf2">项目管理员</div>
										<template v-if="itm.replyUserId">
											<img src="../assets/response_to_icon.png" class="review-item-to-icon" />
											<div class="review-item-user">{{itm.replyUserName}}</div>
											<div class="author-icon" v-if="itm.replyUserId == info.userId">作者</div>
											<div v-if="itm.replyUserId== 163666 || itm.replyUserId==163690" class="sgf2">项目管理员</div>
										</template>
									</div>
									<div class="review-item-con2">{{itm.content}}</div>
									<div class="flex-row align-center space-between">
										<div class="flex-row align-center">
											<div class="review-item-date">{{itm.createTime}}</div>
											<div class="response-btn" @click="responseReview(index,3,idx)">回复
											</div>
											<div class="author-praise-status" v-if="itm.authorLike == 1">作者赞过</div>
										</div>
										<div class="flex-row align-center" @click="praiseReview(index,idx,2)">
											<img src="../assets/praise_icon_select.png" class="review-item-praise-icon"
												v-if="itm.isLike==1" />
											<img src="../assets/praise_icon.png" class="review-item-praise-icon"
												v-else />
											<div class="review-item-praise-num" v-if="itm.likeTotal >0">
												{{itm.formatLikeTotal}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
					<div class="xian"></div>
					<div class="flex-row align-center justify-center zhankai-sec"
						v-if='item.replyPageInfo.total > item.replyPageInfo.list.length'
						@click="zhankaiResponse(index)">
						<!-- <template v-if='item.is_zhankai == 0'> -->
						<div class="zhankai-text">展开更多回复</div>
						<img src="../assets/zhankai.png" class="zhankai-icon" />
						<!-- </template> -->
						<!-- <template v-else>
									<div class="zhankai-text">收起</div>
									<img src="../assets/zhankai.png" class="shouqi-icon" />
								</template> -->
					</div>
				</div>
			</van-list>
		</div>
		<!-- 评论列表结束 -->
		<div class="height120"></div>
		<div class="review-input-sec">
			<div class="review-input-btn" @click="showReviewInput()">文明发言</div>
		</div>
		<div class="review-input-modal-sec align-end justify-center flex-row"
			:style="'bottom:'+ (showInput== 0?'-9999px':'0')">
			<textarea ref="mytextarea" @keypress="checkMsg" :placeholder="placeholder" class="review-input-modal-area"
				@keyup.enter='send()' enterkeyhint="send" v-model="msg"></textarea>
			<div class="send-btn" @click="send()">发送</div>
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	import LoginWrap from '@/components/Login.vue';
	import Compressor from 'compressorjs';
	Vue.use(Vant);
	export default {
		data() {
			return {
				status: 1,
				showToupiaoModal: false, //是否展示投票弹窗
				showFailModal: false, //是否展示验证失败弹窗
				showReviewListModal: false, //是否展示评论列表
				words: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
				userChoiceItemId: 2, //用户选择的选项id
				showLogin: false,
				userChoiceItemIndex: -1, //用户点击投票选择的选项index下标
				votes: [{}, {}, {}, {}, {}, {}, {}, {}], //投票列表
				voteNum: '', //用户输入的投票份数
				id: '',
				projectId: '',
				info: {},
				isFollow: 0,
				userVoteNum: 0, //可投票数
				recordData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
					index: 0,
					columns: []
				},

				loading: false,
				error: false,
				finished: false,
				pageNum: 1,
				pageSize: 10,

				showInput: 0,
				msg: '',
				msgType: 1, //1.发表评论,2 回复评论 3回复回复
				subId: '',
				placeholder: '',
				subindex: '', //回复一级下标
				ssubindex: '', //回复二级下标

				total: '',
				list: [],
				immediateCheck: true,
				signData: {},
				showSign: false,
				isAdmin: 0
			}
		},
		computed: {
			islogin() {
				return this.$store.state.user.token ? true : false
			},
		},
		components: {
			LoginWrap,
		},
		methods: {
			navVoteDetail() {
				if (this.info.voteRecordUrl) {
					window.location.href = this.info.voteRecordUrl
				}
			},
			afterRead(file) {
				const that = this;
				that.$toast.loading({
					message: '上传中...',
					forbidClick: true,
					duration: 0,
				});
				new Compressor(file.file, {
					success(compressorFile) {
						const formData = new FormData();
						formData.append('file', compressorFile, compressorFile.name);
						formData.append('voteId', that.id);

						that.axios.post(config.requestUrl + '/front/forum/uploadVoteFile', formData, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': that.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								that.getVoteDetails();
								that.$toast('上传成功');
							} else {
								that.$toast.fail('上传失败')
							}
							console.log(response)
						}, response => {
							that.$toast.fail('上传失败，请稍后重试');
						})
					},
					error(err) {
						that.$toast.fail('上传失败，请稍后重试');
					},
				});
			},
			getVoteRecordList() {
				const that = this;
				let params = new FormData()
				let pageNum = this.recordData.pageNum;
				this.recordData.pageNum = this.recordData.pageNum + 1
				params.append('pageNum', pageNum)
				params.append('pageSize', this.recordData.pageSize)
				params.append('voteId', this.id)
				that.axios.post(config.requestUrl + '/front/forum/getVoteRecordList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].userAddress = util.formatAddress(newList[i].userAddress)
						}
						if (newList.length < that.recordData.pageSize) that.recordData.finished = true;
						// that.recordData.pageNum = that.recordData.pageNum + 1;
						that.recordData.list = that.recordData.list.concat(newList);
					} else {
						that.recordData.error = true
						that.recordData.pageNum = that.recordData.pageNum - 1
						that.$toast.fail(response.data.msg);
					}
					that.recordData.loading = false;
				}, response => {
					that.recordData.error = true
					that.recordData.loading = false;
					that.recordData.pageNum = that.recordData.pageNum - 1
					that.$toast.fail('获取失败');
				})
			},
			selectOption(index) {
				this.userChoiceItemIndex = index;
			},
			getVoteDetails() {
				const that = this;
				let params = new FormData()
				params.append('voteId', that.id)
				params.append('projectId', that.projectId)
				that.axios.post(config.requestUrl + '/front/forum/getVoteDetails', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.info = response.data.obj;
						let voteOptionList = that.info.voteOptionList;
						let currentNft = 0;
						for (let i in voteOptionList) {
							voteOptionList[i].select = false;
							currentNft += voteOptionList[i].currentSupportTotal
						}
						that.info.currentNft = currentNft;
						that.info.voteOptionList = voteOptionList
						that.info.formatDate = util.surplusDay(that.info.endTime.replace(/-/g, "/"))
						that.info.formatDate2 = util.formatDate(that.info.endTime.replace(/-/g, "/"))
						that.info.formatVoteRecordUrl = '';
						if (response.data.obj.voteRecordUrl) {
							let arr = response.data.obj.voteRecordUrl.split('/');
							let end = arr[arr.length - 1];
							let str = '#' + end.slice(0, 7)
							that.info.formatVoteRecordUrl = str
							console.log(arr)
							console.log(end)
							console.log(str)
						}
						if (that.$store.state.user.token) {
							that.checkIsFollow();
							that.addReadQuantity();
							that.getUserVoteOption();
							that.getUserInfo();
						}
						that.$forceUpdate();
						// setInterval(() => {
						// 	that.info.voteOptionList[1].currentSupportRate = that.info.voteOptionList[1]
						// 		.currentSupportRate + 0.1;
						// 	that.$forceUpdate()
						// }, 100)
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			getUserInfo() {
				const that = this;
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/web3/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj.userInfo.id == that.info.userId) {
							that.isAdmin = 1;
							that.$forceUpdate();
						}
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			//阅读数量+1
			addReadQuantity() {
				const that = this;
				let params = new FormData()
				params.append('targetId', that.id)
				params.append('targetType', 3)
				that.axios.post(config.requestUrl + '/front/forum/addReadQuantity', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					let a = 1;
				})
			},
			getUserVoteOption() {
				const that = this;
				let params = new FormData()
				params.append('voteId', that.id)
				that.axios.post(config.requestUrl + '/front/forum/getUserVoteOption', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let data = response.data.obj;
						let voteOptionList = that.info.voteOptionList;
						for (let i in voteOptionList) {
							for (let j of data) {
								if (voteOptionList[i].id == j) {
									voteOptionList[i].select = true;
								}
							}
						}
						that.info.voteOptionList = voteOptionList
						that.$forceUpdate()
					}
				})
			},
			checkIsFollow() {
				const that = this;
				let params = new FormData()
				params.append('targetUserId', that.info.userId)
				that.axios.post(config.requestUrl + '/front/forum/checkIsFollow', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.isFollow = response.data.obj;
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			addFollow() {
				const that = this;
				let params = new FormData()
				params.append('targetUserId', that.info.userId)
				that.axios.post(config.requestUrl + '/front/forum/addFollow', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast('关注成功')
						that.isFollow = 1;
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			delFollow() {
				const that = this;
				let params = new FormData()
				params.append('targetUserId', that.info.userId)
				that.axios.post(config.requestUrl + '/front/forum/delFollow', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast('取消关注成功')
						that.isFollow = 0;
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			login() {
				this.showLogin = true;
				this.$forceUpdate()
			},
			closeLogin() {
				this.showLogin = false;
				if (this.$store.state.user.token) {
					this.checkIsFollow();
					this.addReadQuantity();
					this.getUserVoteOption();
					this.getUserInfo();
				}
			},
			// 投票时选择选项
			selectChoice(index) {
				let choices = this.choices;
				for (var i in choices) {
					this.choices[i].select = 0;
				}
				this.choices[index].select = 1;
			},
			voteNumChange(e) {
				this.voteNum = e.target.value;
			},
			// 展示评论列表
			showReviewList() {
				this.showReviewListModal = true;
			},
			// 放弃投票按钮
			abandon() {
				this.showFailModal = false;
			},
			closeToupiaoModal() {
				this.showToupiaoModal = false
			},
			stop() {
				event.stopPropagation();
			},
			// 去交易市场按钮
			goDeal() {
				window.location.href = "https://shop.metacbc.cn"
			},
			toupiao() {
				if (!this.$store.state.user.token) {
					this.login();
					return;
				}
				const that = this;
				let params = new FormData()
				params.append('voteId', that.id)
				that.axios.post(config.requestUrl + '/front/forum/getVoteQuantity', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.userVoteNum = response.data.obj;
						if (response.data.obj <= 0) {
							that.showFailModal = true
						} else {
							that.showToupiaoModal = true;
							that.userChoiceItemIndex = -1;
							that.voteNum = '';
						}
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})

			},
			// 提交投票结果
			submit() {
				const that = this;

				if (that.userChoiceItemIndex == -1) {
					that.$toast('请选择选项');
					return;
				}
				if (that.voteNum <= 0 || that.voteNum > that.userVoteNum) {
					that.$toast('请输入有效的票数');
					return;
				}
				that.$toast.loading({
					duration: 0
				})
				let params = new FormData()
				params.append('voteId', that.id)
				params.append('voteQuantity', that.voteNum)
				params.append('voteOptionId', that.info.voteOptionList[that.userChoiceItemIndex].id)
				that.axios.post(config.requestUrl + '/front/forum/getVoteDigitalSignature', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let data = response.data.obj;
						let signData = {
							sign: data.sign
						};
						delete data.sign;
						signData.other = data;
						that.signData = signData;
						that.showSign = true;
						that.$toast.clear();
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})

			},
			next() {
				const that = this;

				if (that.userChoiceItemIndex == -1) {
					that.$toast('请选择选项');
					return;
				}
				if (that.voteNum <= 0 || that.voteNum > that.userVoteNum) {
					that.$toast('请输入有效的票数');
					return;
				}
				that.$toast.loading({
					duration: 0
				})
				let params = new FormData()
				params.append('voteId', that.id)
				params.append('voteQuantity', that.voteNum)
				params.append('signStr', that.signData.sign)
				params.append('voteOptionId', that.info.voteOptionList[that.userChoiceItemIndex].id)
				that.axios.post(config.requestUrl + '/front/forum/supportVote', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						that.$router.replace('/toupiaosuccess')
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			// 收藏或者取消收藏该投票
			collect() {
				if (this.isCollect == 0) {
					this.isCollect = 1;
					this.$toast('收藏成功');
				} else {
					this.isCollect = 0;
					this.$toast('取消收藏成功');
				}
			},
			//获取评论
			getCommentListByTargetId() {
				const that = this;
				that.loading = true;
				let params = new FormData()
				params.append('pageNum', this.pageNum)
				params.append('pageSize', this.pageSize)
				params.append('targetId', this.id)
				params.append('targetType', 3)
				that.axios.post(config.requestUrl + '/front/forum/getCommentListByTargetId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.total = response.data.obj.total;
						that.$toast.clear();
						let list = that.list;
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							newList[i].createTime = util.formatCommentDate(createTime)
							newList[i].formatLikeTotal = util.formatLikeTotal(newList[i].likeTotal)

							for (let j in newList[i].replyPageInfo.list) {
								let createTime2 = newList[i].replyPageInfo.list[j].createTime.replace(/-/g, "/");
								newList[i].replyPageInfo.list[j].createTime = util.formatCommentDate(createTime2)
								newList[i].replyPageInfo.list[j].formatLikeTotal = util.formatLikeTotal(newList[i]
									.replyPageInfo.list[j].likeTotal)
							}
						}
						list = list.concat(newList);
						if (newList.length < that.pageSize) that.finished = true;
						that.pageNum = that.pageNum + 1;
						that.loading = false
						that.list = list;
					} else {
						that.error = true
						that.$toast.fail(response.data.msg);
					}
					that.loading = false
				}, response => {
					that.error = true
					that.loading = false
					that.$toast.fail('获取失败');
				})
			},
			// 回复评论消息
			responseReview(index, type, sindex) {
				if (!this.$store.state.user.token) {
					this.showLogin = true;
					return;
				}
				let that = this;
				this.type = type;
				this.msg = '';
				this.subindex = index;
				this.ssubindex = sindex;
				if (type == 2) {
					let toUserName = that.list[index].userName
					that.placeholder = '@' + toUserName;
					this.subId = that.list[index].id
				} else {
					let toUserName = that.list[index].replyPageInfo.list[sindex].userName
					that.placeholder = '@' + toUserName;
					this.subId = that.list[index].replyPageInfo.list[sindex].id
				}
				this.showInput = 1;
				this.$refs.mytextarea.focus();
			},
			// 给评论点赞
			praiseReview(index, pindex, type) {
				const that = this;
				let params = new FormData();
				let status = '';
				if (type == 1) {
					status = that.list[index].isLike;
				} else {
					status = that.list[index].replyPageInfo.list[pindex].isLike
				}
				let url = ''
				if (status == 0) {
					url = '/front/forum/addLike';
					if (type == 1) {
						params.append('targetType', 4)
						params.append('targetId', that.list[index].id)
					} else {
						params.append('targetType', 5)
						params.append('targetId', that.list[index].replyPageInfo.list[pindex].id)
					}
				} else {
					url = '/front/forum/delLike'
					if (type == 1) {
						params.append('targetType', 4)
						params.append('targetId', that.list[index].id)
					} else {
						params.append('targetType', 5)
						params.append('targetId', that.list[index].replyPageInfo.list[pindex].id)
					}
				}

				that.axios.post(config.requestUrl + url, params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (status == 0) {
							if (type == 1) {
								that.list[index].isLike = 1;
								that.list[index].likeTotal++;
								that.list[index].formatLikeTotal = util.formatLikeTotal(that.list[index].likeTotal)
							} else {
								that.list[index].replyPageInfo.list[pindex].isLike = 1;
								that.list[index].replyPageInfo.list[pindex].likeTotal++;
								that.list[index].replyPageInfo.list[pindex].formatLikeTotal =
									util.formatLikeTotal(that.list[index].replyPageInfo.list[pindex].likeTotal)
							}
							that.$toast('点赞成功');
						} else {
							if (type == 1) {
								that.list[index].isLike = 0;
								that.list[index].likeTotal--;
								that.list[index].formatLikeTotal = util.formatLikeTotal(that.list[index].likeTotal)
							} else {
								that.list[index].replyPageInfo.list[pindex].isLike = 0;
								that.list[index].replyPageInfo.list[pindex].likeTotal--;
								that.list[index].replyPageInfo.list[pindex].formatLikeTotal =
									util.formatLikeTotal(that.list[index].replyPageInfo.list[pindex].likeTotal)
							}
							that.$toast('取消点赞成功');
						}

					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('点赞失败，请稍后重试');
				})
			},
			// 展开或者收起回复，收起展开是否要考虑分页
			zhankaiResponse(index) {
				const that = this;
				let params = new FormData()
				let length = this.list[index].replyPageInfo.list.length;
				if (length >= this.list[index].replyPageInfo.total) return;
				that.$toast.loading({
					forbidClick: true,
					duration: 0,
				});
				let pageNum = Math.ceil(length / 5) + 1;
				params.append('commentId', this.list[index].id)
				params.append('pageNum', pageNum)
				params.append('pageSize', 5)
				params.append('targetId', this.id)
				params.append('targetType', 3)
				that.axios.post(config.requestUrl + '/front/forum/getReplyListByCommentId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							newList[i].createTime = util.formatCommentDate(createTime)
							newList[i].formatLikeTotal = util.formatLikeTotal(newList[i].likeTotal)
						}
						let list = that.list[index].replyPageInfo.list.concat(response.data.obj.list);
						that.list[index].replyPageInfo.list = list
					} else {
						that.error = true
						that.$toast.fail(response.data.msg);
					}
					that.loading = false
				}, response => {
					that.error = true
					that.loading = false
					that.$toast.fail('获取失败');
				})
			},
			showReviewInput() {
				if (!this.$store.state.user.token) {
					this.showLogin = true;
					return;
				}
				this.msg = '';
				this.placeholder = '请输入评论';
				this.type = 1;
				this.showInput = 1;
				this.$refs.mytextarea.focus();
			},
			checkMsg(e) {
				if (e.keyCode === 32 || e.keyCode === 13) {
					e.preventDefault();
					return;
				}
			},
			//发送评论
			send() {
				const that = this;
				if (that.msg.trim() == '') {
					that.$toast('请输入内容');
					return;
				}
				that.showInput = false;
				that.$refs.mytextarea.blur();
				that.$toast.loading({
					// message: '...',
					forbidClick: true,
					duration: 0,
				})
				if (that.type == 1) {
					let params = new FormData()
					params.append('content', that.msg)
					params.append('targetId', that.id)
					params.append('targetType', 3)
					that.axios.post(config.requestUrl + '/front/forum/addComment', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							let list = that.list;
							let newList = response.data.obj;
							let createTime = newList.createTime.replace(/-/g, "/");
							newList.createTime = util.formatCommentDate(createTime)
							newList.replyPageInfo = {
								list: [],
								total: 0
							}
							newList.formatLikeTotal = util.formatLikeTotal(newList.likeTotal)
							that.list.unshift(newList);
							that.$toast('发表成功');
						} else {
							that.$toast.fail(response.data.msg);
						}
					}, response => {
						that.$toast.fail('获取失败，请稍后重试');
					})
				} else {
					let params = new FormData()
					params.append('content', that.msg.trim())
					params.append('targetId', that.id)
					params.append('targetType', 3)
					params.append('commentId', that.list[that.subindex].id)
					if (that.type == 3) {
						params.append('replyId', that.list[that.subindex].replyPageInfo.list[that.ssubindex].id)
					}
					that.axios.post(config.requestUrl + '/front/forum/addReply', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							let newList = response.data.obj;
							let createTime = newList.createTime.replace(/-/g, "/");
							newList.createTime = util.formatCommentDate(createTime)

							newList.formatLikeTotal = util.formatLikeTotal(newList.likeTotal)
							// that.list[that.subindex].replyPageInfo.list.unshift(newList);
							that.list[that.subindex].replyPageInfo.list.push(newList);
							that.$toast('发表成功');
						} else {
							that.$toast.fail(response.data.msg);
						}
					}, response => {
						that.$toast.fail('获取失败，请稍后重试');
					})
				}

			}
		},
		mounted() {
			this.id = this.$route.query.id;
			if (!this.id) {
				this.$router.replace('/')
				return;
			}
			this.projectId = this.$route.query.projectId;
			if (!this.projectId) {
				this.$router.replace('/')
				return;
			}
			this.getVoteDetails()
			this.getVoteRecordList();

		},
	}
</script>

<style scoped="scoped">
	.zhanwei20 {
		height: 20px;
	}

	.container {
		width: 100%;
		/* height: 100%; */
		background: #F5F5F5;
	}

	.creator-sec {
		width: 710px;
		height: 130px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0 20px 0 20px;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.creator-head {
		width: 70px;
		height: 70px;
		margin-right: 21px;
	}

	.creator-name-sec {}

	.creator-user-name {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
	}

	.creator-time {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		margin-top: 10px;
	}

	.attention-btn {
		width: 120px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 27px;
		border: 1px solid #6236FF;
		text-align: center;
		font-size: 28px;
		color: #6236FF;
		line-height: 50px;
	}

	.detail-sec {
		margin: 20px auto 0 auto;
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 41px 20px 17px 20px;
		box-sizing: border-box;
	}

	.status-icon {
		width: 124px;
		height: 108px;
		position: absolute;
		top: -38px;
		right: 36px;
	}

	.title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 28px;
		letter-spacing: 1px;
	}

	.desc {
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		margin-top: 52px;
	}

	.detail-items-sec {
		width: 670px;
		background: #F5F5F5;
		border-radius: 10px;
		padding: 26px 25px 78px 25px;
		margin-top: 30px;
		box-sizing: border-box;
	}

	.please-icon {
		width: 408px;
		height: 37px;
	}

	.end_days {
		/* width: 230px; */
		height: 42px;
		background: #FFFFFF;
		border-radius: 21px;
		margin: 20px auto 0 auto;
		padding: 0 20px;
		text-align: center;
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 42px;
	}

	.item-item {
		width: 620px;
		background: #FFFFFF;
		border-radius: 30px;
		margin-top: 20px;
		padding: 5px 0;
		/* overflow: hidden; */
	}

	.item-select {
		width: 26px;
		height: 26px;
		background: #F7B500;
		border: 1px solid #F7B500;
		margin-right: 26px;
		border-radius: 50%;
		flex-shrink: 0;
	}

	.item-icon {
		width: 20px;
		height: 20px;
		margin-left: 26px;
		font-size: 26px;
		font-weight: bold;
		color: #F7B500;
		line-height: 26px;
	}

	.item-text {
		width: 414px;
		font-size: 28px;
		color: #000000;
		line-height: 59px;
		margin-left: 20px;
		word-wrap: break-word;
		word-break: break-all;
	}

	.item-text-active {
		width: 414px;
		font-size: 28px;
		font-weight: bold;
		line-height: 59px;
		color: #000000;
		margin-left: 20px;
	}

	.item-persent-sec {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		border-radius: 30px;
		overflow: hidden;
	}

	.item-persent-sec-small {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 30px 0 0 30px;
		height: 100%;
		overflow: hidden;
	}

	.persent-bg {
		width: 620px;
		height: 100%;
		background: rgba(247, 181, 0, 0.06);
		border-radius: 30px 0 0 30px;
		border: 1px solid #F7B500;
		box-sizing: border-box;
	}

	.persent-bg2 {
		width: 620px;
		height: 100%;
		background: rgba(247, 181, 0, 0.06);
		border-radius: 30px;
		/* border-radius: 30px 0 0 30px; */
		border: 1px solid #F7B500;
		box-sizing: border-box;
	}

	.item-persent-big-num {
		font-size: 24px;
		color: #FA6400;
		line-height: 33px;
		margin-right: 19px;
	}



	.item-persent-small-num {
		font-size: 24px;
		color: #FA6400;
		line-height: 33px;
		margin-left: 15px;
	}

	.rule-sec {
		width: 620px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 60px;
		padding: 43px 30px 49px 30px;
		box-sizing: border-box;
	}

	.rule-img-sec {
		position: absolute;
		top: -30px;
		left: 0;
		right: 0;
		width: 620px;
	}

	.rule-left {
		width: 16px;
		height: 16px;
		background: #F7B500;
		border-radius: 50%;
	}

	.rule-icon {
		width: 60px;
		height: 60px;
		margin: 0 22px 0 22px;
	}

	.rule-right {
		width: 16px;
		height: 16px;
		background: #F7B500;
		border-radius: 50%;
	}

	.rule-con {
		font-size: 24px;
		color: #000000;
		line-height: 43px;
	}

	.ipfs-sec {
		margin-top: 50px;
	}

	.ipfs {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 33px;
	}

	.ipfs-con {
		font-size: 24px;
		font-weight: bold;
		color: #6236FF;
		line-height: 33px;
		border-bottom: 1px solid #6236FF;
	}

	.zhifu-sec {
		margin-top: 10px;
	}

	.zhifu {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 24px;
	}

	.zhifu-con {
		font-size: 24px;
		font-weight: bold;
		color: #000000;
		line-height: 24px;
	}

	.toupiao-btn {
		width: 400px;
		height: 60px;
		background: #F7B500;
		border-radius: 30px;
		margin-top: 61px;
		text-align: center;
		font-size: 28px;
		color: #000000;
		line-height: 60px;
	}

	.toupiao-btn-grey {
		width: 400px;
		height: 60px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 30px;
		margin-top: 61px;
		text-align: center;
		font-size: 28px;
		color: #000000;
		line-height: 60px;
	}

	.review-input-sec {
		position: fixed;
		bottom: 0;
		left: 20px;
		right: 0;
		width: 710px;
		height: 125px;
		background: #F5F5F5;
	}

	.review-input-con-sec {
		width: 710px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0 32px 0 20px;
		box-sizing: border-box;
	}

	.review-input {
		width: 350px;
		height: 60px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.25);
		box-sizing: border-box;
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 60px;
		padding-left: 22px;
	}

	.review-sec {
		margin-left: 42px;
	}

	.review-icon {
		width: 30px;
		height: 30px;
	}

	.review-num {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 22px;
		margin-left: 10px;
	}

	/* 投票弹窗开始 */
	.toupiao-modal-mask {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		background: rgba(0, 0, 0, 0.7);
	}

	.toupiao-modal {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 670px;
		height: 1019px;
	}

	.toupiao-bg {
		width: 670px;
		height: 1019px;
	}

	.toupiao-modal-con {
		position: absolute;
		top: 93px;
		left: 27px;
		width: 616px;
		height: 900px;
		background: #FFFFFF;
		border-radius: 21px;
		overflow-y: scroll;
		padding: 38px 28px 38px 28px;
		box-sizing: border-box;
	}

	.toupiao-can-sec {
		width: 560px;
		height: 120px;
		background: rgba(247, 181, 0, 0.08);
		border-radius: 10px;
		font-size: 26px;
		color: #FA9700;
		line-height: 40px;
		letter-spacing: 1px;
		padding: 20px 30px;
		box-sizing: border-box;
	}

	.toupiao-can-text {}

	.toupiao-can-num {
		font-size: 30px;
		color: #FA6400;
	}

	.choice-title-sec {
		margin-top: 40px;
	}

	.choice-left-icon {
		width: 8px;
		height: 11px;
		rotate: 180deg;
	}

	.choice-text {
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 37px;
		letter-spacing: 1px;
		margin-left: 10px;
		margin-right: 10px;
	}

	.choice-right-icon {
		width: 8px;
		height: 11px;
	}

	.choice-items {}

	.choice-item {
		width: 560px;
		background: rgba(0, 0, 0, 0.05);
		border-radius: 30px;
		padding: 10px 28px;
		box-sizing: border-box;
		margin-top: 20px;
	}

	.choice-item-icon {
		width: 20px;
		height: 20px;
		font-size: 26px;
		font-weight: bold;
		color: #F7B500;
		line-height: 26px;
	}

	.choice-item-text {
		width: 400px;
		font-size: 28px;
		color: #000000;
		line-height: 32px;
		margin-left: 20px;
	}

	.fenshu-title-sec {
		margin-top: 40px;
	}

	.fill-toupiao-input {
		width: 560px;
		height: 80px;
		background: #FFFFFF;
		text-align: center;
		margin-top: 25px;
	}

	.fill-toupiao-num {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 80px;
		letter-spacing: 1px;
		width: 560px;
		height: 80px;
		background: #FFFFFF;
		text-align: center;
		border: 1px solid #F7B500;
		box-sizing: border-box;
		border-radius: 10px;
	}

	.submit-btn {
		width: 400px;
		height: 60px;
		background: #F7B500;
		border-radius: 30px;
		text-align: center;
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 60px;
		margin-top: 70px;
	}

	.choice-no-select {
		width: 20px;
		height: 20px;
		border: 1px solid #F7B500;
		border-radius: 50%;
	}

	.choice-select {
		width: 20px;
		height: 20px;
		background: #F7B500;
		border: 1px solid #F7B500;
		border-radius: 50%;
	}

	/* 投票弹窗结束 */
	/* 投票失败弹窗开始 */
	.fail-modal-mask {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
	}

	.fail-modal {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 630px;
		height: 444px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	.fail-tishi-icon {
		position: absolute;
		left: 41px;
		top: -89px;
		width: 140px;
		height: 140px;
	}

	.toupiao-tishi-title {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 47px;
		margin-top: 39px;
	}

	.toupiao-tishi-con {
		width: 550px;
		height: 204px;
		background: #FFEBDE;
		border-radius: 10px;
		margin: 0 auto;
		padding: 37px 60px 0 60px;
		font-size: 34px;
		font-family: HuXiaoBo-NanShen, HuXiaoBo;
		color: #D36F2C;
		line-height: 56px;
		text-align: center;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.toupiao-fail-btn-sec {
		margin-top: 36px;
	}

	.toupiao-fail-btn {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 0px 20px;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 88px;
		text-align: center;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.toupiao-fail-btn2 {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 20px 0px;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 88px;
		text-align: center;
	}

	/* 投票失败弹窗结束 */
	/* 上传公示文件开始 */
	.gongshi-sec {
		margin-top: 20px;
		width: 670px;
		height: 280px;
		background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(247, 181, 0, 0.33) 49%, rgba(255, 255, 255, 0) 100%);
	}

	.gongshi-title {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 40px;
		margin-top: 61px;
	}

	.gongshi-upload-sec {
		width: 215px;
		height: 80px;
		background: #F7B500;
		border-radius: 10px;
		margin-top: 31px;
	}

	.gongshi-upload-icon {
		width: 38px;
		height: 31px;
		margin-right: 10px;
	}

	.upload-text {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 40px;
	}

	/* 上传公示文件结束 */
	.height150 {
		height: 150px;
	}

	.gongshi-file-sec {
		width: 710px;
		/* height: 348px; */
		height: auto;
		background: #FFFFFF;
		border-radius: 10px;
		margin: 20px auto 0 auto;
	}

	.gongshi-file-img {
		width: 710px;
		height: auth;
		background: #FFFFFF;
		border-radius: 10px;
	}

	/* 投票明细开始 */
	.vote-list {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0 45px 0 42px;
		box-sizing: border-box;
		margin: 20px auto 0 auto;
	}

	.vote-icon {
		width: 48px;
		height: 50px;
		margin-right: 21px;
	}

	.vote-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 40px;
		width: 623px;
		height: 109px;
	}

	.vote-list-items {
		border-top: 2px solid #F7B500;
		width: 623px;
		height: 84px;
		font-size: 24px;
		color: #000000;
		line-height: 33px;
	}

	.vote-list-user {
		padding-left: 60px;
		width: 292px;
		box-sizing: border-box;
	}

	.vote-list-choice {
		width: 72px;
		margin-left: 20px;
		margin-right: 20px;
	}

	.vote-list-num {
		width: 72px;
		text-align: right;
	}

	.vote-item {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		width: 623px;
		height: 84px;
	}

	.vote-item-user {
		width: 292px;
	}

	.vote-item-head {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 20px;
	}

	.vote-item-name {

		font-size: 24px;
		color: #000000;
		line-height: 24px;
	}

	.vote-item-choice {
		width: 40px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #F7B500;
		text-align: center;
		font-size: 24px;
		color: #000000;
		line-height: 40px;
		margin-left: 34px;
	}

	.vote-item-num {
		font-size: 24px;
		color: #000000;
		line-height: 24px;
	}

	/* 投票明细结束 */
	/* 区块链签名部分开始 */
	.blockchain-sign-sec {
		width: 750px;
		height: 1130px;
		background: #F5F5F5;
		border-radius: 20px 20px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
	}

	.blockchain-sign-title {
		margin-top: 40px;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
		text-align: center;
	}

	.blockchain-sign-top {
		width: 660px;
		height: 410px;
		background: #FFFFFF;
		border-radius: 10px;
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 40px;
		letter-spacing: 1px;
		margin-top: 48px;
		padding: 33px 45px;
		box-sizing: border-box;
		word-wrap: break-word;
		word-break: break-all;
	}

	.blockchain-sign-top div {
		word-wrap: break-word;
		word-break: break-all;
		text-indent: 40px;
	}

	.blockchain-sign-top div:first-child {
		text-indent: 0
	}

	.blockchain-sign-top div:last-child {
		text-indent: 0
	}

	.blockchain-sign-bottom {
		width: 660px;
		height: 410px;
		background: #FFFFFF;
		border-radius: 10px;
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 40px;
		letter-spacing: 1px;
		margin-top: 20px;
		padding: 33px 45px;
		box-sizing: border-box;
		word-wrap: break-word;
		word-break: break-all;
	}

	.blockchain-sign-btn {
		width: 500px;
		height: 80px;
		background: #F7B500;
		border-radius: 40px;
		font-size: 32px;
		font-weight: bold;
		color: #000000;
		line-height: 80px;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 50px;
	}

	/* 区块链签名部分结束 */


	.attention-btn-false {
		width: 120px;
		height: 50px;
		background: #ffffff;
		border-radius: 27px;
		border: 1px solid #6236FF;
		text-align: center;
		font-size: 28px;
		color: #6236FF;
		line-height: 50px;
	}



	.review-sec {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 30px;
		margin: 20px auto 0 auto;
		box-sizing: border-box;
	}

	.review-all-num {
		font-size: 26px;
		color: #000000;
		line-height: 37px;
		letter-spacing: 1px;
		text-align: center;
	}

	.review-item-sec {
		margin-top: 43px;
	}

	.review-item-head {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-right: 10px;
	}

	.review-item-user {
		/* font-size: 20px; */
		font-size: 22px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		/* line-height: 20px; */
		line-height: 22px;
	}

	.author-icon {
		width: 62px;
		height: 26px;
		background: #6236FF;
		border-radius: 12px;
		margin-left: 2px;
		text-align: center;
		/* font-size: 18px; */
		font-size: 20px;
		color: #FFFFFF;
		line-height: 26px;
	}

	.review-item-to-icon {
		width: 8px;
		height: 12px;
		margin: 0 10px;
	}

	.review-item-con {
		width: 578px;
		/* font-size: 24px; */

		font-size: 26px;
		color: #000000;
		line-height: 36px;
		margin-bottom: 10px;
		margin-top: 11px;
	}

	.review-item-con2 {
		width: 520px;

		/* font-size: 24px; */
		font-size: 26px;
		color: #000000;
		line-height: 36px;
		margin-bottom: 10px;
		margin-top: 11px;
	}

	.review-item-date {
		/* font-size: 20px; */
		font-size: 22px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 20px;
	}

	.response-btn {
		/* font-size: 20px; */
		font-size: 22px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		margin-left: 40px;
	}

	.review-item-praise-icon {
		width: 20px;
		height: 18px;
	}

	.review-item-praise-num {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		margin-left: 10px;
	}

	.review-item-head2 {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		margin-right: 10px;
	}

	.height120 {
		height: 120px;
	}

	.review-input-sec {
		width: 750px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px -1px 16px 0px rgba(0, 0, 0, 0.1);
		border-radius: 10px 10px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 20px;
		box-sizing: border-box;
	}

	.review-input-btn {
		width: 710px;
		height: 60px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.25);
		padding-left: 30px;
		box-sizing: border-box;
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 60px;
	}

	.review-input-modal-sec {
		position: fixed;
		left: 0;
		bottom: 0;
	}

	.review-input-modal-sec {
		width: 750px;
		height: 220px;
		background: #FFFFFF;
	}

	.review-input-modal-area {
		width: 590px;
		height: 160px;
		padding: 20px 35px;
		background: #F3F3F3;
		border-radius: 10px;
		box-sizing: border-box;
	}

	.send-btn {
		text-align: center;
		width: 80px;
		height: 60px;
		background: #fc295b;
		color: #ffffff;
		line-height: 60px;
		font-size: 22px;
		border-radius: 26px;
		margin-left: 20px;
	}

	.author-praise-status {
		height: 25px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		text-align: center;
		margin-left: 40px;
		/* font-size: 16px; */
		font-size: 18px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 25px;
		padding: 0 8px;
	}


	.response-list {
		margin-left: 70px;
	}

	.response-item {
		margin-top: 30px;
	}

	.xian {
		width: 578px;
		height: 1px;
		background: #F5F5F5;
		margin-top: 20px;
		margin-bottom: 10px;
		margin-left: 70px;
	}

	.zhankai-sec {
		margin-left: 70px;
	}

	.zhankai-text {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 22px;
	}

	.zhankai-icon {
		width: 11px;
		height: 6px;
		margin-left: 8px;
	}

	.shouqi-icon {
		width: 11px;
		height: 6px;
		margin-left: 8px;
		rotate: 180deg;
	}

	.cureent-nft {
		margin-top: 30px;
		font-size: 26px;
		color: #000000;
	}
</style>